import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import Axios from 'axios';
import apiString from '../components/apiString';
import moment from 'moment';
import { eventDate1, eventDate2 } from '../components/utilities';
import { makeAgeGroupTitle } from '../components/utilities';

const tableStyles = {
    styles: {
        fontSize: 9,
        lineColor: 200, // A lighter grey color for the borders, can be [200,200,200] for RGB
        lineWidth: 0.01, // Thin borders
        valign: 'middle', // Vertically align text in the middle of the cell
        halign: 'center', // Horizontally align text to the left of the cell
        cellPadding: { top: .05, bottom: .05 }
    },
    headStyles: {
        fillColor: [220, 220, 220], // A light grey background for header cells
        textColor: 50, // Dark grey text for the header, can be [50, 50, 50] for RGB
        fontStyle: 'bold'
    },
    bodyStyles: {
        fillColor: [255, 255, 255], // White background for body cells
        textColor: 0, // Black text for the body, can be [0, 0, 0] for RGB
    }
};

const classes = ['Primary', 'Beginner', 'Novice', 'Intermediate'];

const checkedInConverter = {
    open: 'checkedinopen',
    jig: 'checkedinjig',
    prechamps: 'checkedinprechamp',
    precomp: 'checkedinprecomp',
    national: 'checkedinscottish'
};

const resultTitleConverter = {
    open: 'RESULTS\nPHOENIX OPEN CHAMPIONSHIP',
    jig: 'RESULTS\nPHOENIX IRISH JIG CHALLENGE',
    prechamps: 'RESULTS\nPHOENIX PRE-CHAMPIONSHIP',
    precomp: 'RESULTS\nPHOENIX PRE-PREMIER COMPETITION',
    national: 'RESULTS\n SCOTTISH NATIONAL DANCE PREMIERSHIP'
};

const overallPlaces = {
    open: 6,
    jig: 3,
    prechamps: 1,
    precomp: 1,
    national: 6
}

const fullNameConverter = {
    open: 'Premier Competition',
    jig: 'Irish Jig Challenge - 5 Steps',
    prechamps: 'Pre-Championship',
    precomp: 'Pre-Premier Competition',
    national: 'Scottish National Dance Premiership'
}

const likeConverter = {
    open: 'Premier Competition',
    jig: 'Irish Jig',
    prechamps: 'Pre-Championship',
    precomp: 'Pre-Premier Competition',
    national: 'Scottish National'
};

const danceIdConverter = {
    open: 1,
    jig: 2,
    prechamps: 4,
    precomp: 3,
    national: 5
};

const eventDayConverter = {
    open: 1,
    jig: 1,
    prechamps: 2,
    precomp: 2,
    national: 2
};

const marginVertical = .5;

const groupByAge = (comp, dancers, ageGroups, titlePrepend) => {
    const eventDate = eventDayConverter[comp] === 1 ? eventDate1 : eventDate2;
    const ageGroupList = ageGroups.map(ageGroup => {
        ageGroup.dancers = [];
        ageGroup.title = titlePrepend + makeAgeGroupTitle(ageGroup.startingnumber, ageGroup.undernumber);
        for (let i = 0; i < dancers.length; i++) {
            const dancer = dancers[i];
            const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
            const age = eventDate.diff(dob, 'years');
            const ageMatch = age >= +ageGroup.startingnumber && age < +ageGroup.undernumber;
            if (ageMatch) {
                ageGroup.dancers.push(dancer);
            }
        }
        return ageGroup;
    });
    return ageGroupList;
}

const groupForPreComp = (dancers, ageGroups) => {
    const ageGroupReference = {};
    const classList = classes.map(classification => {
        ageGroupReference[classification] = [];
        const data = {};
        data.title = classification;
        data.dancers = [];
        for (let i = 0; i < dancers.length; i++) {
            const dancer = dancers[i];
            if (dancer.classification === classification) data.dancers.push(dancer);
        }
        return data;
    });
    if (ageGroups.length === 0) return classList;

    let finalList = [];
    for (let i = 0; i < ageGroups.length; i++) {
        const ageGroup = ageGroups[i];
        ageGroupReference[ageGroup.classification].push(ageGroup);
    }
    for (let i = 0; i < classList.length; i++) {
        const item = classList[i];
        if (ageGroupReference[item.title].length === 0) {
            finalList.push(item);
        }else {
            let subGroups = groupByAge('precomp', item.dancers, ageGroupReference[item.title], item.title + ' - ');
            for (let ii = 0; ii < subGroups.length; ii++) {
                finalList.push(subGroups[ii]);
            }
        }
    }
    return finalList;
}

const getDataForLineupSheets = comp => {
    return new Promise(resolve => {
        const data = new FormData();
        data.set('action', 'getDataForLineupSheets');
        data.set('like', likeConverter[comp]);
        data.set('checkedin', checkedInConverter[comp]);
        data.set('danceId', danceIdConverter[comp]);
        Axios.post(apiString, data).then(response => {
            const theDancers = response.data.dancers;
            if (comp === 'open' || comp === 'jig' || comp === 'national') resolve(groupByAge(comp, theDancers, response.data.ageGroups, ''));
            if (comp === 'prechamps' && response.data.ageGroups.length === 0) resolve([{title: 'Pre-Championship', dancers: theDancers}]);
            if (comp === 'prechamps' && response.data.ageGroups.length > 0) resolve(groupByAge(comp, theDancers, response.data.ageGroups, ''));
            if (comp === 'precomp') resolve(groupForPreComp(theDancers, response.data.ageGroups));
        });
    });
}

export const generateLineUpSheets = async comp => {
    const titles = {
        open: 'SATURDAY, FEBRUARY 15TH, 2025\nPREMIER COMPETITION\nDANCER LINE UP',
        // jig: 'SATURDAY, MARCH 2ND, 2024\nJIG CHALLENGE\nDANCER LINE UP',
        // prechamps: 'SUNDAY, MARCH 3RD, 2024\nPRE-CHAMPIONSHIP COMPETITION\nDANCER LINE UP',
        precomp: 'SATURDAY, FEBRUARY 15TH, 2025\nPRE-PREMIER COMPETITION\nDANCER LINE UP',
        // national: 'SUNDAY, MARCH 3RD, 2024\nPREMIERSHIP\nDANCER LINE UP'
    };

    const data = await getDataForLineupSheets(comp);
    const pdf = await printLineUpSheets(data, titles[comp]);
    return pdf;
}

export const printLineUpSheets = (groupList, title) => {
    return new Promise(resolve => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'in',
            format: 'letter',
        });
        // Set text to the center of the page
        const pageCenter = pdf.internal.pageSize.getWidth() / 2;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
    
        const leftColumnStartX = 0.5; // For example, half an inch from the left edge of the page
        const rightColumnStartX = pageWidth / 2 + 0.25; // For example, center of the page plus some gutter
        const verticalSpaceBetweenTables = .3;
        const startingCursorLocation = 1.1;
    
        const checkFit = (currentCursorLocation, rowCount) => {
            const rowHeight = .3;
            const tableHeight = (rowCount*rowHeight) + verticalSpaceBetweenTables + marginVertical;
            const fits = currentCursorLocation + tableHeight < pageHeight;
            return fits;
        }
    
        // Add the title to the PDF
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold'); // Set the font to ensure consistent measurement
        pdf.text(title, pageCenter, marginVertical, { align: 'center'});
    
        
    
        let currentColumn = 'left';
        let cursorLocation = startingCursorLocation;

    
        groupList.forEach((group, index) => {
            const dancerList = group.dancers.map(dancer => {
                return [dancer.dancenumber, dancer.firstname, dancer.lastname];
            });

            dancerList.reverse();
    
            // Add the tables to the PDF
            autoTable(pdf, {
                body: dancerList,
                head: [[{ content: group.title, colSpan: 3, styles: { halign: 'center', fillColor: [221, 221, 221] } }]],
                tableWidth: 2.5,
                theme: 'grid',
                margin: {left: currentColumn === 'left' ? leftColumnStartX : rightColumnStartX},
                startY: cursorLocation, // Start the table after the title, adjust as needed
                ...tableStyles
            });
    
            // Check future table placement
            if (index < groupList.length - 1) {
                cursorLocation = pdf.lastAutoTable.finalY;
                const nextTableFits = checkFit(cursorLocation, groupList[index+1].dancers.length);
                if (nextTableFits) cursorLocation = cursorLocation + verticalSpaceBetweenTables;
                if (!nextTableFits) {
                    cursorLocation = startingCursorLocation;
                    if (currentColumn === 'right') pdf.addPage();
                    currentColumn = currentColumn === 'left' ? 'right' : 'left';
                }
            }
        });
        resolve(pdf);
    });
}

export const generateResultSheets = async comp => {
    const data = await getDataForResultSheets(comp);

    let pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter',
    });

    // Set text to the center of the page
    const pageCenter = pdf.internal.pageSize.getWidth() / 2;
    const pageWidth = pdf.internal.pageSize.getWidth();

    const leftColumnStartX = 0.5; // For example, half an inch from the left edge of the page
    const rightColumnStartX = pageWidth / 2 + 0.25; // For example, center of the page plus some gutter
    const startingCursorLocation = 1.1;
    const verticalSpaceBetweenTables = .3;
    
    let overall = {buckets: {}, dancers: {}};
    let individual = {buckets: {}, dances: {}};

    for (let i = 0; i < data.data.length; i++) {
        const item = data.data[i];
        if (item.bucket === '') {
            overall.dancers = JSON.parse(JSON.stringify(addDancerToOverallResults(item, overall.dancers, data.dancerNames, data.data, comp)));
            individual.dances = JSON.parse(JSON.stringify(addDancerToIndividualResults(item, individual.dances, data.dancerNames, data.data, comp)));
        }
        if (item.bucket !== '' && item.subbucket === '') {
            if (!overall.buckets[item.bucket]) overall.buckets[item.bucket] = {subbuckets: {}, dancers: {}};
            if (!individual.buckets[item.bucket]) individual.buckets[item.bucket] = {subbuckets: {}, dances: {}};
            overall.buckets[item.bucket].dancers = JSON.parse(JSON.stringify(addDancerToOverallResults(item, overall.buckets[item.bucket].dancers, data.dancerNames, data.data, comp)));
            individual.buckets[item.bucket].dances = JSON.parse(JSON.stringify(addDancerToIndividualResults(item, individual.buckets[item.bucket].dances, data.dancerNames, data.data, comp)));
        }

        if (item.subbucket !== '') {
            if (!overall.buckets[item.bucket]) overall.buckets[item.bucket] = {subbuckets: {}, dancers: {}};
            if (!overall.buckets[item.bucket].subbuckets[item.subbucket]) overall.buckets[item.bucket].subbuckets[item.subbucket] = {dancers: {}};
            if (!individual.buckets[item.bucket]) individual.buckets[item.bucket] = {subbuckets: {}, dances: {}};
            if (!individual.buckets[item.bucket].subbuckets[item.subbucket]) individual.buckets[item.bucket].subbuckets[item.subbucket] = {dances: {}};
            overall.buckets[item.bucket].subbuckets[item.subbucket].dancers = JSON.parse(JSON.stringify(addDancerToOverallResults(item, overall.buckets[item.bucket].subbuckets[item.subbucket].dancers, data.dancerNames, data.data, comp)));
            individual.buckets[item.bucket].subbuckets[item.subbucket].dances = JSON.parse(JSON.stringify(addDancerToIndividualResults(item, individual.buckets[item.bucket].subbuckets[item.subbucket].dances, data.dancerNames, data.data, comp)));
        }
    }
    let completed = 0;
    let keepGoing = data.data.length > 0;

    if (Object.keys(overall.buckets).length === 0 && keepGoing) {
        const title = resultTitleConverter[comp];
        pdf = await printResultSheet(comp, pdf, pageCenter, leftColumnStartX, rightColumnStartX, startingCursorLocation, title, comp === 'jig', completed > 0, individual.dances, overall.dancers, data.dataMatrix, '', verticalSpaceBetweenTables);
        completed++;
    }else if (Object.keys(overall.buckets).length > 0 && keepGoing) {
        const buckets = Object.keys(overall.buckets);
        let newBuckets = buckets.includes('Primary') ? JSON.parse(JSON.stringify(classes)) : sortAgeGroups(buckets);
        for (let i = 0; i < newBuckets.length; i++) {
            const bucketName = newBuckets[i];
            const bucket = overall.buckets[bucketName];
            if (Object.keys(bucket.subbuckets).length === 0) {
                const danceList = Object.keys(individual.buckets[bucketName].dances);
                if (danceList.length > 0) {
                    let title = resultTitleConverter[comp];
                    if (bucketName.includes('&')) {
                        const split = bucketName.split(' & Under');
                        title += '\n' + makeAgeGroupTitle(split[0], split[1]).toUpperCase();
                    }else {
                        title += '\n' + bucketName.toUpperCase();
                    }
                    pdf = await printResultSheet(comp, pdf, pageCenter, leftColumnStartX, rightColumnStartX, startingCursorLocation, title, comp === 'jig', completed > 0, individual.buckets[bucketName].dances, bucket.dancers, data.dataMatrix, bucketName, verticalSpaceBetweenTables);
                    completed++;
                }
            }else {
                const subbuckets = Object.keys(bucket.subbuckets);
                let newSubbuckets = subbuckets.includes('Primary') ? JSON.parse(JSON.stringify(classes)) : sortAgeGroups(subbuckets);
                for (let ii = 0; ii < newSubbuckets.length; ii++) {
                    const subbucketName = newSubbuckets[ii];
                    const subbucket = bucket.subbuckets[subbucketName];
                    const danceList = Object.keys(individual.buckets[bucketName].subbuckets[subbucketName].dances);
                    if (danceList.length > 0) {
                        let title = resultTitleConverter[comp];
                        if (bucketName.includes('&')) {
                            const split = bucketName.split(' & Under');
                            title += '\n' + makeAgeGroupTitle(split[0], split[1]).toUpperCase();
                        }else {
                            title += '\n' + bucketName.toUpperCase();
                        }
                        if (subbucketName.includes('&')) {
                            const split = subbucketName.split(' & Under');
                            title += '\n' + makeAgeGroupTitle(split[0], split[1]).toUpperCase();
                        }else {
                            title += '\n' + subbucketName.toUpperCase();
                        }
                        pdf = await printResultSheet(comp, pdf, pageCenter, leftColumnStartX, rightColumnStartX, startingCursorLocation, title, comp === 'jig', completed > 0, individual.buckets[bucketName].subbuckets[subbucketName].dances, subbucket.dancers, data.dataMatrix, bucketName, verticalSpaceBetweenTables);
                        completed++;
                    }
                }
            }
        }
    }
    //no results
    if (completed === 0) {
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold'); // Set the font to ensure consistent measurement
        pdf.text('No Results', pageCenter, marginVertical, { align: 'center'});
    }
    return pdf;
}

const sortAgeGroups = (ageGroups) => {
    ageGroups.sort((a, b) => {
        let numA = parseInt(a.match(/^\d+/), 10);
        let numB = parseInt(b.match(/^\d+/), 10);
        return numA - numB; // Compare these numbers to sort
    });
    return ageGroups;
}

const getDances = (dataMatrix, comp, bucketName) => {
    if (comp === 'open' || comp === 'national') return dataMatrix[fullNameConverter[comp]].ageGroups[bucketName];
    if (comp === 'jig') return ['Irish Jig 5 step'];
    if (comp === 'precomp') return dataMatrix[fullNameConverter[comp]].classifications[bucketName].dances;
    if (comp === 'prechamps') return dataMatrix[fullNameConverter[comp]].dances;
}




export const printResultSheet = (comp, pdf, pageCenter, leftColumnStartX, rightColumnStartX, startingCursorLocation, title, centerTable, addPage, individualDances, overallDancers, dataMatrix, bucketName, verticalSpaceBetweenTables) => {
    return new Promise(resolve => {
        if (addPage) pdf.addPage();
    
        // Add the title to the PDF
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold'); // Set the font to ensure consistent measurement
        pdf.text(title, pageCenter, marginVertical, { align: 'center'});

        let callList = {};

        const dances = getDances(dataMatrix, comp, bucketName);

        // Object.keys(individualDances).forEach((dance, index) => {
        let leftBottom = startingCursorLocation - verticalSpaceBetweenTables;
        let rightBottom = startingCursorLocation - verticalSpaceBetweenTables;
        dances.forEach((dance, index) => {
            if (individualDances[dance]) {
                let list = individualDances[dance];
                list.sort((a, b) => {
                    if (+a.place < +b.place) return -1;
                    if (+a.place > +b.place) return 1;
                    return 0;
                });
                const dancerList = list.map(dancer => {
                    const dancerString = dancer.danceNumber + ' - ' + dancer.name;
                    if (!callList[+dancer.danceNumber]) callList[dancer.danceNumber] = dancerString;
                    return [dancer.place, dancer.danceNumber, dancer.name];
                });
    
                autoTable(pdf, {
                    body: dancerList,
                    head: [[{ content: dance, colSpan: 3, styles: { halign: 'center', fillColor: [221, 221, 221] } }]],
                    tableWidth: 2.5,
                    theme: 'grid',
                    margin: centerTable ? {left: pageCenter - 1.25} : {left: ((index === 1 || index === 3) ? rightColumnStartX : leftColumnStartX)},
                    startY: ((index === 0 || index === 2) ? leftBottom : rightBottom) + verticalSpaceBetweenTables, 
                    ...tableStyles
                });
                if (index === 0 || index === 2) leftBottom = pdf.lastAutoTable.finalY;
                if (index === 1 || index === 3) rightBottom = pdf.lastAutoTable.finalY;
            }
        });

        let bottom = leftBottom > rightBottom ? leftBottom : rightBottom;

        const overalls = calculateOverall(overallDancers, comp);

        autoTable(pdf, {
            body: prepForOverallTable(comp, overalls),
            head: [[{ content: overallPlaces[comp] === 1 ? 'Aggregate' : 'Overall', colSpan: (overallPlaces[comp] === 1 ? 2 : 3), styles: { halign: 'center', fillColor: [221, 221, 221] } }]],
            tableWidth: 2.5,
            theme: 'grid',
            margin: leftColumnStartX,
            startY: bottom + verticalSpaceBetweenTables, 
            ...tableStyles
        });
        if (comp === 'open' || comp === 'national') {
            autoTable(pdf, {
                body: prepForOverallDetails(overalls),
                head: [
                    ['Champ Points', '# Firsts', 'Jdg Points', 'Jdg Prizes']
                ],
                tableWidth: 5,
                theme: 'grid',
                margin: leftColumnStartX+2.5,
                startY: bottom + verticalSpaceBetweenTables, 
                ...tableStyles
            });
        }
        bottom = pdf.lastAutoTable.finalY;


        let callKeys = Object.keys(callList);
        callKeys.sort();

        let callGroups = [];
        for (let i = 0; i < callKeys.length; i = i + 3) {
            let miniGroup = [];
            for (let ii = i; ii < i + 3; ii++) {
                const danceNumber = callKeys[ii];
                const dancer = callList[danceNumber];
                miniGroup.push(dancer);
            }
            callGroups.push(miniGroup);
        }

        autoTable(pdf, {
            body: callGroups,
            head: [[{ content: 'Call List', colSpan: 3, styles: { halign: 'center', fillColor: [221, 221, 221] } }]],
            tableWidth: 7.5,
            theme: 'grid',
            margin: leftColumnStartX,
            startY: bottom + verticalSpaceBetweenTables, 
            ...tableStyles
        });

        resolve(pdf);
    });
}

const prepForOverallDetails = (data) => {
    return data.map(item => {
        return [item.totalPoints, item.firsts, item.judgesTotal, item.prizes];
    });
}

const prepForOverallTable = (comp, data) => {
    return data.map(item => {
        return overallPlaces[comp] === 1 ? [item.danceNumber, item.name] : [item.place, item.danceNumber, item.name];
    });
}

const calculateOverall = (overallDancers, comp) => {
    let original = [];
    Object.keys(overallDancers).forEach(danceNumber => {
        original.push(overallDancers[danceNumber]);
    });



    //now that we have the list of dancers and the following numbers:  totalPoints, firsts, judgesTotal, prizes we need to sort the dancers in order.  Here is how we determine the order/rank:  highest totalPoints, then if there is a tie, we subsort by highest firsts, then if there is still a tie, we subsort by highest judgesTotal, then if there is still a tie we sort by highest prizes.  
    original.sort((a, b) => {
        if (+a.totalPoints > +b.totalPoints) return -1;
        if (+a.totalPoints < +b.totalPoints) return 1;
        if (+a.firsts > +b.firsts) return -1;
        if (+a.firsts < +b.firsts) return 1;
        if (+a.judgesTotal > +b.judgesTotal) return -1;
        if (+a.judgesTotal < +b.judgesTotal) return 1;
        if (+a.prizes > +b.prizes) return -1;
        if (+a.prizes < +b.prizes) return 1;
        return 0;
    });

    //now that we have the array sorted appropriately, we need to assign places to each dancer.  we will do this by putting an integer into the "place" attribute of the element.  we cannot just iterate through the array because there could be ties.  so we need to keep track somehow.  
    let places = [];
    let place = 1;
    for (let i = 0; i < original.length; i++) {
        const dancer = original[i];
        if (i === 0) {
            dancer.place = place;
        }else {
            const previousDancer = original[i-1];
            if (+dancer.totalPoints === +previousDancer.totalPoints && +dancer.firsts === +previousDancer.firsts && +dancer.judgesTotal === +previousDancer.judgesTotal && +dancer.prizes === +previousDancer.prizes) {
                dancer.place = previousDancer.place;
            }else {
                dancer.place = place;
            }
        }
        places.push(dancer);
        place++;
    }

    let final = [];
    for (let i = 0; i < places.length; i++) {
        const dancer = places[i];
        if (dancer.place <= overallPlaces[comp]) final.push(dancer);
    }
    return final;
}

const addDancerToOverallResults = (item, dancers, dancerNames, data, comp) => {
    if (+item.judgestotal === 0) return dancers;
    if (!dancers[item.dancenumber]) dancers[item.dancenumber] = {danceNumber: item.dancenumber, judgesTotal: 0, totalPoints: 0, firsts: 0, prizes: 0, name: dancerNames[item.dancenumber]};
    dancers[item.dancenumber].judgesTotal += +item.judgestotal;
    dancers[item.dancenumber].totalPoints += +item.totalpoints;
    if (+item.place === 1) dancers[item.dancenumber].firsts++;
    if (placeChecker(data.length, comp, +item.place)) dancers[item.dancenumber].prizes++;
    return dancers;
}

const addDancerToIndividualResults = (item, dances, dancerNames, data, comp) => {
    if (+item.judgestotal === 0) return dances;
    if (placeChecker(data.length, comp, +item.place)) {
        if (!dances[item.dance]) dances[item.dance] = [];
        dances[item.dance].push({danceNumber: item.dancenumber, name: dancerNames[item.dancenumber], place: item.place, judgesTotal: item.judgestotal});
    }
    return dances;
}

const placeChecker = (compLength, comp, place) => {
    if (place === null) return false;
    if (comp === 'open' || comp === 'national' || comp === 'prechamps') return place <= 6;
    if (comp === 'jig') return place <= 3;
    if (comp === 'precomp') {
        if (compLength > 7) return place <= 6; //8 or more dancers  = 6 places
        if (compLength > 4) return place <= 3; //5-7 dancers = 3 places
        return place <= 1; //4 or fewer dancers = 1 place
    }
}

const getDataForResultSheets = comp => {
    return new Promise(resolve => {
        const data = new FormData();
        data.set('action', 'getDataForResultSheets');
        data.set('comp', fullNameConverter[comp]);
        Axios.post(apiString, data).then(response => {
            let dancerNames = {};
            for (let i = 0; i < response.data.dancers.length; i++) {
                const dancer = response.data.dancers[i];
                dancerNames[dancer.dancenumber] = dancer.firstname + ' ' + dancer.lastname;
            }
            resolve({data: response.data.scrutineering, dancerNames, dataMatrix: response.data.dataMatrix});
        });
    });
}