import Axios from 'axios';
import apiString from '../components/apiString';
import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Header, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { eventDate1, eventDate2 } from '../components/utilities';
import DancerTable from '../components/dancerTable';

const compConverter = {
    open: 'Open Champs',
    // jig: 'Jig',
    // prechamps: 'Pre-Champs',
    precomp: 'Pre-Premier Comp',
    // national: 'Nationals'
};

const checkedInOptions = [
    {value: 'checkedinopen', key: 'checkedinopen', text: 'Open'},
    // {value: 'checkedinjig', key: 'checkedinjig', text: 'Jig'},
    // {value: 'checkedinprechamp', key: 'checkedinprechamp', text: 'Pre-Champ'},
    {value: 'checkedinprecomp', key: 'checkedinprecomp', text: 'Pre-Premier'},
    // {value: 'checkedinscottish', key: 'checkedinscottish', text: 'Nationals'}
];

const checkedInToCompetitionConverter = {
    checkedinopen: 'Premier Competition',
    // checkedinjig: 'Irish Jig Challenge - 5 Steps',
    // checkedinprechamp: 'Pre-Championship',
    checkedinprecomp: 'Pre-Premier Competition',
    // checkedinscottish: 'Scottish National Dance Premiership'
}

const Registration = ({setLoading}) => {
    const [dancers, setDancers] = useState([]);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [publishOptions, setPublishOptions] = useState({});
    const [checkedInSelect, setCheckedInSelect] = useState(['checkedinopen']);
    const [masterDanceList, setMasterDanceList] = useState([]);
    const [masterSwitch, setMasterSwitch] = useState(false);

    useEffect(() => {
        setLoading(true);
        pullDancers('');
    }, []);

    const pullDancers = () => {
        setLoading(true);

        let pulls = [];

        const formdata = new FormData();
        formdata.set('action', 'getDancerList');
        formdata.set('searchString', '');
        pulls.push(Axios.post(apiString, formdata));

        const pullDances = new FormData();
		pullDances.set('action', 'getDanceList');
		pulls.push(Axios.post(apiString, pullDances));

        const pullMasterSwitch = new FormData();
        pullMasterSwitch.set('action', 'getMasterSwitch');
        pulls.push(Axios.post(apiString, pullMasterSwitch));
        
        Axios.all(pulls).then(response => {
            const list = response[0].data.map(dancer => {
                const dob = moment(dancer.birthdatestring, 'MM/DD/YYYY');
                dancer.age1 = eventDate1.diff(dob, 'years');
                dancer.age2 = eventDate2.diff(dob, 'years');
                dancer.age = dancer.age1 === dancer.age2 ? dancer.age1 : dancer.age1 + '/' + dancer.age2;
                dancer.name = dancer.firstname + ' ' + dancer.lastname;
                dancer.teacher = dancer.teacherlastname;
                dancer.highlight = +dancer.highlight;
                return dancer;
            });
            setMasterSwitch(+response[2].data === 1 ? true : false);
            setMasterDanceList(response[1].data);
            setDancers(list);
            // calculateDancers(list, checkedInSelect);
        });
    }

    const changeCheckedInOptions = value => {
        const newValue = value.length === 0 ? checkedInSelect : value
        setCheckedInSelect(newValue);
        // calculateDancers(dancers, newValue);
    }

    const calculateDancers = (dancerList, checkedInOptions) => {
        setLoading(false);
        if (checkedInOptions.length === 5) return dancerList;
        return dancerList.filter(dancer => {
            let match = false;
            for (let i = 0; i < checkedInOptions.length; i++) {
                let dances = JSON.parse(JSON.stringify(dancer.dances));
                if (typeof dances === 'string') dances = dances.split(',');
                // const dances = dancer.dances.split(',');
                if (dances.includes(checkedInToCompetitionConverter[checkedInOptions[i]])) {
                    match = true;
                    break;
                }
            }
            return match;
        });
    }

    const handlePublishClick = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'pullLineUpPublishOptions');
        Axios.post(apiString, data).then(response => {
            const newOptions = {};
            for (let i = 0; i < response.data.length; i++) {
                newOptions[response.data[i].competition] = +response.data[i].publish === 1 ? true : false;
            }
            setPublishOptions(newOptions);
            setLoading(false);
            setShowPublishModal(true);
        });
    }

    const handlePublishChange = comp => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'updateLineUpPublishOptions');
        data.set('competition', comp);
        data.set('publish', publishOptions[comp] ? 0 : 1);
        Axios.post(apiString, data).then(() => {
            const newOptions = {...publishOptions};
            newOptions[comp] = !publishOptions[comp];
            setPublishOptions(newOptions);
            setLoading(false);
        });
    }

    const handleMasterSwitchClick = () => {
        setLoading(true);
        const data = new FormData();
        data.set('action', 'updateMasterSwitch');
        data.set('masterSwitch', masterSwitch ? 0 : 1);
        Axios.post(apiString, data).then(() => {
            pullDancers();
        });
    }
    
    return (
        <>
            <div style={{padding: 20, margin: '0 auto', textAlign: 'center'}}>
                <Button style={{marginTop: 30}} color={masterSwitch ? 'blue' : 'red'} onClick={handleMasterSwitchClick}>Online Registration is {masterSwitch ? 'Open' : 'Closed'}</Button>
                <div style={{width: '100%', height: 20}}></div>

                <Button style={{marginBottom: 30}} primary onClick={handlePublishClick}>Publish Options</Button>

                <Form style={{width: 250, margin: '0 auto'}}>
                    <Form.Field control={Select} value={checkedInSelect} options={checkedInOptions} multiple label="Check In Options" fluid onChange={(e, data) => changeCheckedInOptions(data.value)} />
                </Form>

                <div style={{position: 'relative'}}>
                    <DancerTable setLoading={setLoading} ogDancers={calculateDancers(dancers, checkedInSelect)} ogMasterDanceList={masterDanceList} pullDancers={pullDancers} checkedInSelect={checkedInSelect} />
                </div>
            </div>

            {showPublishModal &&
                <Modal size="mini" open closeOnDimmerClick={false} closeOnEscape={false}>
                    <Header as="h3" textAlign="center">Publish Options</Header>
                    <Modal.Content style={{textAlign: 'center'}}>
                        {Object.keys(publishOptions).map((comp, index) => {
                            return <React.Fragment key={index}>
                                <Button style={{width: 250}} color={publishOptions[comp] ? 'red' : 'blue'} onClick={() => handlePublishChange(comp)}>{compConverter[comp]}: {publishOptions[comp] ? 'Unpublish' : 'Publish'}</Button>
                                <div style={{width: '100%', height: 10}}></div>
                            </React.Fragment>
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => setShowPublishModal(false)}>Done</Button>
                    </Modal.Actions>
                </Modal> 
            }
        </>
        
    );
}

export default Registration;